import * as React from 'react';
import {PackageMvn} from "../../../types/index";
import {AuthContextProps} from "react-oidc-context";
import Grid from "@mui/material/Grid";
import {Table, TableBody, TableHead, TableRow} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import catalogueService from "../../../services/CatalogueService";

export const PackageMvnListComponent: React.FC<{
    packageMvnDtos: PackageMvn[],
    auth: AuthContextProps,
    updatePackageMvnDtos: any,
    updateMode: boolean, disabled?: boolean
}> = (
    props: { packageMvnDtos: PackageMvn[], auth: AuthContextProps, updatePackageMvnDtos: any, updateMode: boolean, disabled?: boolean }
) => {

    const onDeletePackageMvn = (idPackageNpm: number | undefined | null) => {
        if (idPackageNpm) {
            catalogueService.deletePackageMvn(props.auth, idPackageNpm).then(value => {
                props.updatePackageMvnDtos(props.packageMvnDtos.filter(value => value.id != idPackageNpm));
            })
        }
    }

    return (props.packageMvnDtos && props.packageMvnDtos.length > 0) ? (
        <Grid item xs={12} md={12}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>GroupId</TableCell>
                        <TableCell align="right">ArtifactId</TableCell>
                        <TableCell align="right">Version</TableCell>
                        {props.updateMode &&  <TableCell align="right">Supprimer</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.packageMvnDtos.map((row: PackageMvn) => (
                        <TableRow
                            key={row.id}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell component="th" scope="row">
                                {row.groupId}
                            </TableCell>
                            <TableCell align="right">{row.artifactId}</TableCell>
                            <TableCell align="right">{row.version}</TableCell>
                            {props.updateMode &&  <TableCell align="right">
                                <IconButton aria-label="delete" color="warning" disabled={props.disabled} onClick={event => {
                                    onDeletePackageMvn(row.id);
                                }}>
                                    <DeleteOutlineIcon/>
                                </IconButton>
                            </TableCell>}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

        </Grid>
    ) : (<></>);
}