import * as React from "react";
import dayjs, {Dayjs} from "dayjs";
import {QueryClient, useQueryClient} from "@tanstack/react-query";
import Loading from "../../../components/Loading";
import Stack from "@mui/material/Stack";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import MetricsGraph from "../../../components/MetricsGraph";
import Title from "../../../components/Title";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import {AuthContextProps, useAuth} from "react-oidc-context";
import metricsService from "../../../services/MetricsService";

export default function PodComponent(props:any) {
    const now: dayjs.Dayjs = dayjs();
    const auth: AuthContextProps = useAuth();

    const queryClient: QueryClient = useQueryClient()
    const [valueSince, setValueSince] = React.useState<Dayjs | null>(now.add(-1, 'day'));
    const [valueTo, setValueTo] = React.useState<Dayjs | null>(now);


    const { isLoading, error, data, isFetching, isPreviousData } = metricsService.metricsNode(auth, props, valueSince, valueTo);

    React.useEffect(() => {
        if (!isPreviousData && data?.hasMore) {
            metricsService.metricsQueryClientNode(auth, queryClient, props, valueSince, valueTo);
        }
    }, [data, isPreviousData, valueSince, valueTo, queryClient])

    if (isLoading) {
        return <Loading />
    }

    return (
        <Paper
            sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 680,
            }}
        >
            <Stack direction="row" justifyContent="space-between" spacing={2}>
                <Stack spacing={2}>
                    <Title>{props.nodeName}</Title>
                    <Chip label={props.name} />
                </Stack>
                <Stack spacing={2}>
                    <DatePicker
                        label="valueSince"
                        value={valueSince}
                        maxDate={now}
                        onChange={
                            (newValue) => {
                                if ( dayjs(valueSince).isAfter(valueTo) ) {
                                    setValueTo(newValue)
                                }
                                setValueSince(newValue)
                            }
                        }
                    />
                    <DatePicker
                        label="valueTo"
                        value={valueTo}
                        minDate={valueSince}
                        maxDate={now}
                        onChange={(newValue) => setValueTo(newValue)}
                    />
                </Stack>


            </Stack>

            <MetricsGraph data={data} type={'cpu'} />
            <MetricsGraph data={data} type={'memory'} />
            <MetricsGraph data={data} type={'pods'} />
        </Paper>
    )

}