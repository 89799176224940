import * as React from 'react';
import Grid from "@mui/material/Grid";
import Title from "../../../components/Title";
import Stack from "@mui/material/Stack";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import dayjs from "dayjs";
import 'dayjs/locale/fr'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function InfosComp(props:any) {
    const now = dayjs()

    return (
        <Grid item md={6} sm={12} xs={12}  key={'infos'}>
            <Card sx={{minHeight: 180}}>
                <CardContent>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack>
                            <Title>Produits actif</Title>
                            <Typography component="p" variant="h1">
                                {props.nb}
                            </Typography>
                        </Stack>
                        <Box sx={{ typography: 'subtitle2' }}>le {now.locale('fr').format('dddd D  MMMM YYYY')}</Box>
                    </Stack>
                </CardContent>
            </Card>
        </Grid>
    );
}