import * as React from 'react';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import InfosComp from "./Cards/Infos";
import MetricComp from "./Cards/Metrics";
import productService from "../../services/ProductService";
import {AuthContextProps, useAuth} from "react-oidc-context";

export default function DashboardPage() {
    const auth: AuthContextProps = useAuth();
    const [intervalMs, setIntervalMs] = React.useState(1000*30)
    const { isFetching, ...queryInfo } = productService.useGetCount(auth);

    return (
        <Container maxWidth="xl">
            <Box sx={{my: 4}}>
                {queryInfo.isSuccess && (
                    <Grid container spacing={2}>
                        <InfosComp nb={queryInfo.data}/>
                        <MetricComp/>
                    </Grid>
                )}
                {queryInfo.isLoading && 'Loading'}
                {queryInfo.error instanceof Error && queryInfo.error.message}
            </Box>
        </Container>
    );
}