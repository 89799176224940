import * as React from 'react';
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";

export const ProductInfoComponent: React.FC<{
    id?: any, name?: any, step?: any, status?: any
}> = (
    props: { id?: any, name?: any, step?: any, status?: any }
) => {

    const colorChip = (status: string) => {
        if(status === 'PENDING' ) {
            return 'warning';
        } else if(status === 'RUNNING' ) {
            return 'primary';
        } else if(status === 'WORKING' ) {
            return 'primary';
        } else if(status === 'SUCCESS' ) {
            return 'success';
        }

        return 'error';
    }

    return (

        <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
                <Box sx={{typography: 'subtitle2'}}>Identificateur</Box>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
                <Box sx={{typography: 'body1', my: 2}}>{props.id}</Box>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
                <Box sx={{typography: 'subtitle2'}}>Nom</Box>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
                <Box sx={{typography: 'body1', my: 2}}>{props.name}</Box>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
                <Box sx={{typography: 'subtitle2'}}>Status</Box>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
                <Stack direction="row" spacing={2} sx={{m: 2}}>
                    <Chip label={props.step + ': ' + props.status} color={colorChip(props.status)}/>
                </Stack>
            </Grid>
        </Grid>
    );
}