import * as React from 'react'
import Stack from '@mui/material/Stack';
import {Card} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Loading from "../../../components/Loading";
import TitleCard from "../../../components/TitleCard";
import DeployComponent from "./Deploy";
import DeleteComponent from "./Delete";
import productService from "../../../services/ProductService";
import {AuthContextProps, useAuth} from "react-oidc-context";

/*
    "deployed"
    "not-deployed"
    "pending"
 */

export default function AdministrationComponent(props: any) {
    const auth: AuthContextProps = useAuth();

    const [intervalMs, setIntervalMs] = React.useState(1000)

    const {isLoading, error, data, isFetching} = productService.deploymentStatus(auth, props, intervalMs);

    if (isLoading) {
        return <Loading/>
    }

    return (
        <Card sx={{minWidth: 275}}>
            <CardContent>
                <TitleCard>Administration du produit</TitleCard>
                <Stack direction="row" spacing={2}>
                    <DeployComponent id={props.id} status={data.status}/>
                    <DeleteComponent id={props.id} status={data.status}/>
                </Stack>
            </CardContent>

        </Card>
    );
}