import * as React from 'react';
import {VersionLot} from "../../../types/index";
import {AuthContextProps} from "react-oidc-context";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Add from "@mui/icons-material/Add";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import {PackageMvnListContainer} from "../../Store/container/PackageMvnListContainer";
import {PackageNpmContainer} from "../../Store/container/PackageNpmContainer";


export const VersionLotListChooseContainer: React.FC<{
    versionList: VersionLot[],
    versionListChoose: VersionLot[],
    refreshMode: any,
    auth: AuthContextProps
}> = (
    props: {
        versionList: VersionLot[],
        versionListChoose: VersionLot[],
        refreshMode: any,
        auth: AuthContextProps
    }) => {

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const updateVersionLot = (versionLot: VersionLot, addMode: boolean) => {
        props.refreshMode(versionLot, addMode)
    }

    if (props && props?.versionList.length > 0) {
        props.versionList.sort((a, b) => a.numero > b.numero ? -1 : 1);
        return (<>
                {props.versionList.map((value: VersionLot) => {
                    return (
                        <Accordion expanded={expanded === `panel${value.numero}`} onChange={handleChange(`panel${value.numero}`)} key={`panel${value.numero}bh`}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                              aria-controls={`panel${value.numero}bh-content`}
                                              id={`panel${value.numero}bh-header`}>
                                <Grid container direction="row" justifyContent="space-between" alignItems="stretch"
                                      spacing={1}>
                                    <Grid item xs={4} md={4}>
                                        {value.numero}
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        {value.description}
                                    </Grid>
                                    <Grid item xs={2} md={2}>
                                        {props.versionListChoose.filter(versionChoose => {
                                                return versionChoose.id === value.id
                                            }).length > 0 &&
                                            <IconButton aria-label="add" color="warning" onClick={event => {
                                                updateVersionLot(value, false );
                                            }}>
                                                <DeleteOutline/>
                                            </IconButton>
                                        }
                                        {props.versionListChoose.filter(versionChoose => {
                                                return versionChoose.id === value.id
                                            }).length === 0 &&
                                            <IconButton aria-label="add" color="info" onClick={event => {
                                                updateVersionLot(value, true);
                                            }}>
                                                <Add/>
                                            </IconButton>
                                        }
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails sx={{backgroundColor: "gray"}}>
                                <Grid container direction="row" justifyContent="space-between" alignItems="stretch"
                                      spacing={1}>
                                    <Grid item xs={12} md={6}>
                                        <PackageMvnListContainer versionLot={value} auth={props.auth}
                                                                 updateMode={false}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <PackageNpmContainer versionLot={value} auth={props.auth} updateMode={false}/>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    )
                })}</>
        );
    } else {
        return (<>Aucune données</>)
    }
}
