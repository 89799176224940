import * as React from 'react';
import {red} from '@mui/material/colors';
import {Link as RouterLink, LinkProps as RouterLinkProps, MemoryRouter,} from 'react-router-dom';
import {StaticRouter} from 'react-router-dom/server';
import {createTheme} from '@mui/material/styles';

const LinkBehavior = React.forwardRef<
    HTMLAnchorElement,
    Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />;
});
function Router(props: { children?: React.ReactNode }) {
  const { children } = props;
  if (typeof window === 'undefined') {
    return <StaticRouter location="/">{children}</StaticRouter>;
  }
  return (<MemoryRouter>{children}</MemoryRouter>);
}

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
      light: '#757ce8',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    }
  },
  /*components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },*/
});

export default theme;