import * as React from 'react';
import {AuthContextProps} from "react-oidc-context";
import {Table, TableBody, TableContainer, TableRow} from "@mui/material";
import TableCell from "@mui/material/TableCell";

export const ComplementInfoComponent: React.FC<{
    serverAlias?: any, deploy?: any, defaultLanguage?: any, defaultCurrency?: any
}> = (
    props: { serverAlias?: any, deploy?: any, defaultLanguage?: any, defaultCurrency?: any }
) => {

    return (
        <TableContainer sx={{ mb: 2 }}>
            <Table size="small" aria-label="a dense table">
                <TableBody>
                    <TableRow key='t1'>
                        <TableCell component="th" scope="row">serverAlias</TableCell>
                        <TableCell component="th" scope="row">{props.serverAlias}</TableCell>
                    </TableRow>
                    <TableRow key='t2'>
                        <TableCell component="th" scope="row">déploiement</TableCell>
                        <TableCell component="th" scope="row">{props.deploy ? 'oui' : 'non'}</TableCell>
                    </TableRow>

                    <TableRow key='t3'>
                        <TableCell component="th" scope="row">defaultLanguage</TableCell>
                        <TableCell component="th" scope="row">{props.defaultLanguage}</TableCell>
                    </TableRow>
                    <TableRow key='t4'>
                        <TableCell component="th" scope="row">defaultCurrency</TableCell>
                        <TableCell component="th" scope="row">{props.defaultCurrency}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}