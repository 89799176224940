import * as React from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ProductsListDelete from "../Products/ProductsListDelete";

export default function TrashPage() {


    return (
        <Container maxWidth="xl">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ProductsListDelete />
                </Grid>
            </Grid>
        </Container>
    );
}