import * as React from 'react';
import {PackageNpm, VersionLot} from "../../../types/index";
import {AuthContextProps} from "react-oidc-context";
import {PackageNpmListComponent} from "../components/PackageNpmListComponent";
import {PackageNpmDialogComponent} from "../components/PackageNpmDialogComponent";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";


export const PackageNpmContainer: React.FC<{ versionLot: VersionLot, auth: AuthContextProps, updateMode: boolean, disabled?: boolean }> = (
    props: { versionLot: VersionLot, auth: AuthContextProps, updateMode: boolean, disabled?: boolean }
) => {
    const [packageNpmDtos, setPackageNpmDtos] = React.useState<PackageNpm[]>(props.versionLot.packageNpmDtos);

    React.useEffect(() => {
        setPackageNpmDtos(props.versionLot.packageNpmDtos);
    }, [props.versionLot]);

    const updatePackageNpmDtos = (data: PackageNpm[]): void => {
        setPackageNpmDtos(data);
    }

    return (
        <Card>
            <CardContent>
                <PackageNpmDialogComponent versionLot={props.versionLot} packageNpmDtos={packageNpmDtos}
                                           updatePackageNpmDtos={updatePackageNpmDtos} auth={props.auth} hidden={!props.updateMode}/>

                <PackageNpmListComponent packageNpmDtos={packageNpmDtos} updatePackageNpmDtos={updatePackageNpmDtos} disabled={props.disabled}
                                         auth={props.auth} updateMode={props.updateMode}/>
            </CardContent>
        </Card>
    )
}