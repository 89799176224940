import * as React from 'react'
import LoadingButton from '@mui/lab/LoadingButton';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import productService from "../../../services/ProductService";
import {AuthContextProps, useAuth} from "react-oidc-context";

export default function DeployComponent(props: any) {
    const auth: AuthContextProps = useAuth();
    const [status, setStatus] = React.useState(props.status)

    const actionClickDeploy = () => {
        setStatus("Deploy")

        productService.deployProductAssembly(auth, props);
    }

    const actionClickUndeploy = () => {
        setStatus("Undeploy")

        productService.undeployProductAssembly(auth, props);
    }

    return (
        <>
            {props.status === "deployed" ? (
                <LoadingButton
                    onClick={actionClickUndeploy}
                    loading={false}
                    loadingPosition="start"
                    startIcon={<CheckBoxOutlinedIcon/>}
                    variant="contained"
                >
                    <span>Undeploy</span>
                </LoadingButton>
            ) : props.status === "not-deployed" ? (
                <LoadingButton
                    onClick={actionClickDeploy}
                    loading={false}
                    loadingPosition="start"
                    startIcon={<CheckBoxOutlineBlankIcon/>}
                    variant="contained"
                >
                    Deploy
                </LoadingButton>
            ) : (
                <LoadingButton
                    loading={true}
                    loadingPosition="start"
                    startIcon={<CheckBoxOutlineBlankIcon/>}
                    variant="contained"
                >
                    {status}
                </LoadingButton>
            )}
        </>
    );
}