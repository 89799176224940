import * as React from 'react';
import Typography from '@mui/material/Typography';

interface ITitleProps {
    children?: React.ReactNode;
}

export default function TitleCard(props: ITitleProps) {
    return (
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
            {props.children}
        </Typography>
    );
}