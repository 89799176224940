import * as React from 'react';
import {AuthContextProps} from "react-oidc-context";
import {Table, TableBody, TableContainer, TableRow} from "@mui/material";
import TableCell from "@mui/material/TableCell";

export const DataBaseInfoComponent: React.FC<{
    name: any, hostname: any, port: any, username: any, password: any
}> = (
    props: { name: any, hostname: any, port: any, username: any, password: any }
) => {

    return (
        <TableContainer sx={{ mb: 2 }}>
            <Table size="small" aria-label="a dense table">
                <TableBody>
                    <TableRow key='tb1'>
                        <TableCell component="th" scope="row">name</TableCell>
                        <TableCell component="th" scope="row">{props.name}</TableCell>
                    </TableRow>
                    <TableRow key='tb2'>
                        <TableCell component="th" scope="row">hostname</TableCell>
                        <TableCell component="th" scope="row">{props.hostname}</TableCell>
                    </TableRow>
                    <TableRow key='tb3'>
                        <TableCell component="th" scope="row">port</TableCell>
                        <TableCell component="th" scope="row">{props.port}</TableCell>
                    </TableRow>
                    <TableRow key='tb4'>
                        <TableCell component="th" scope="row">username</TableCell>
                        <TableCell component="th" scope="row">{props.username}</TableCell>
                    </TableRow>
                    <TableRow key='tb5'>
                        <TableCell component="th" scope="row">password</TableCell>
                        <TableCell component="th" scope="row">{props.password}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}