import * as React from 'react'
import {Box, Container, Grid} from "@mui/material";

import {useParams} from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

import Title from "../../components/Title";
import AdministrationComponent from "./TabAdmin/Administration";
import ActivityTab from "./TabActivity/Activity";
import HistoryComponent from "./TabMain/components/HistoryComponent";
import IngressComponent from "./TabMain/components/IngressComponent";
import DetailContainer from "./TabMain/container/DetailContainer";
import {ModuleContainer} from "./TabMain/container/ModuleContainer";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography component="span">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function ProductInfoPage() {
    const {id} = useParams<"id">();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Container maxWidth="xl">
            <Grid container spacing={2}>
                <Grid item xs={12}>

                    <Card sx={{minHeight: 0}}>
                        <CardContent style={{backgroundColor: '#ededed'}}>
                            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Vue générale"/>
                                    <Tab label="Activité"/>
                                    <Tab label="Administration"/>
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <Grid container spacing={2}>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <DetailContainer id={id}/>
                                    </Grid>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <ModuleContainer id={id}/>
                                    </Grid>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <IngressComponent id={id}/>
                                        <HistoryComponent id={id}/>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Grid container spacing={2}>
                                    <ActivityTab id={id}/>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <AdministrationComponent id={id}/>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}
