import configurationService from "./services/ConfigurationService";
import Loading from "./components/Loading";
import * as React from "react";
import {AuthProvider} from "react-oidc-context";
import CssBaseline from "@mui/material/CssBaseline";
import {HashRouter} from "react-router-dom";
import App from "./App";


export default function ManagementPage() {

    const {isLoading, error, data, isFetching} = configurationService.getKeycloakConfiguration();

    /*const oidcConfig = {
        authority: "http://localhost:6060/realms/myrealm",
        client_id: "my-todo-list",
        redirect_uri: "http://localhost:3000",
        onSigninCallback: () => {
            window.history.replaceState(
                {},
                document.title,
                window.location.pathname
            )
        }
        //userStore: new WebStorageStateStore({ store: window.localStorage })
    }*/


    if (isLoading) {
        return (
                <Loading/>
        )
    } else {
        return (
            <AuthProvider {...data}>
                    <CssBaseline/>
                    <HashRouter>
                        <App></App>
                    </HashRouter>
            </AuthProvider>
        )
    }

}
