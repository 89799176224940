import * as React from 'react'
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import ServerIcon from "@mui/icons-material/Dns";
import Link from "@mui/material/Link";
import Loading from "../../../../components/Loading";
import Stack from "@mui/material/Stack";
import CardContent from "@mui/material/CardContent";
import TitleCard from "../../../../components/TitleCard";
import {Card} from "@mui/material";
import productService from "../../../../services/ProductService";
import {AuthContextProps, useAuth} from "react-oidc-context";

export interface itemIngress {
    name: string;
    class: string;
    hosts: string[];
}

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
}));

const renderIngress = (item: itemIngress) => (
    <>
        <Box sx={{ typography: 'body1', mt: 2 }}>{item.name}</Box>

        <Box sx={{ typography: 'subtitle2' }}>Hosts</Box>
        {
            item.hosts.map((host: string) => {
                return <Item>
                    <ServerIcon /> <Link href={"https://" + host} target="_blank">{host}</Link>
                </Item>
            })
        }
    </>
);


export default function IngressComponent(props:any) {
    const auth: AuthContextProps = useAuth();

    const { isLoading, error, data, isFetching } = productService.getProductByIdIngress(auth, props);

    if (isLoading) {
        return <Loading />
    }

    return (
        <Card sx={{minHeight: 0, mb: 2}}>
            <CardContent>
                <TitleCard>Ingress</TitleCard>
                <Stack spacing={2}>
                    {
                        data.map((item: itemIngress) => {
                            return renderIngress(item)
                        })
                    }
                </Stack>
            </CardContent>
        </Card>
    )
}