import * as React from 'react'
import {QueryClient, useQueryClient} from '@tanstack/react-query'

import Loading from "../../../../components/Loading"
import List from "@mui/material/List"
import dayjs from "dayjs"
import "dayjs/locale/fr"

import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import Chip from '@mui/material/Chip'
import {Button, Card} from "@mui/material";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import TitleCard from "../../../../components/TitleCard";
import Stack from "@mui/material/Stack";
import productService from "../../../../services/ProductService";
import {AuthContextProps, useAuth} from "react-oidc-context";

export interface RenderListStatus {
    id: string;
    status: string;
    step: string;
    createdAt: number;
}

export default function HistoryComponent(props: any) {
    const queryClient: QueryClient = useQueryClient();
    const auth: AuthContextProps = useAuth();
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    //const [intervalMs, setIntervalMs] = React.useState(2000)

    /*async function fetchProductHistory(page = 0) {
        const { data } = await api.get(`/product-assembly/${props.id}/history-status`, { params: { page: page, per_page: 5 } })
        return data
    }*/

    const {isLoading, error, data, isFetching, isPreviousData} = productService.historyStatus(auth, props, 0)

    React.useEffect(() => {
        if (!isPreviousData && data?.hasMore) {
            productService.productHistory(auth, props, queryClient, page);
        }
    }, [data, isPreviousData, page, queryClient])

    const renderListStatus = (item: RenderListStatus) => (
        <ListItem
            key={item.id}
        >
            <ListItemIcon>
                <Chip label={item.step}/>&nbsp;
                <Chip label={item.status}/>&nbsp;
            </ListItemIcon>
            <Box sx={{typography: 'body1', p: 1}}>{dayjs(item.createdAt).format('DD/MM/YYYY • HH:mm')}</Box>
        </ListItem>
    );

    if (isLoading) {
        return <Loading/>
    }

    return (
        <Card sx={{minHeight: 0}}>
            <CardContent>
                <TitleCard>Historique du produit</TitleCard>
                <List
                    sx={{width: '100%', maxWidth: 400, bgcolor: 'background.paper'}}
                    key={'history-list'}
                >
                    {
                        data.content.map((item: RenderListStatus) => {
                            return renderListStatus(item)
                        })
                    }
                </List>
            </CardContent>
            <Stack direction="row" justifyContent="flex-end" spacing={0}>
                <Button
                    onClick={() => setPage((old) => Math.max(old - 1, 0))}
                    disabled={page === 0}
                >
                    Previous Page
                </Button>
                <Chip label={`${page + 1}/${data?.totalPages}`} variant="outlined"/>
                <Button
                    onClick={() => {
                        setPage((old) => (!data?.last ? old + 1 : old))
                    }}
                    disabled={isPreviousData || data?.last}
                >
                    Next Page
                </Button>
            </Stack>
        </Card>
    );
}