import React from "react";
import Grid from "@mui/material/Grid";
import {FormInputText} from "../../../components/form/FormInputText";
import {FormInputRadioCore} from "../../../components/form/FormInputRadioCore";
import {FormInputDropdownDF} from "../../../components/form/FormInputDropdownDF";
import {FormInputDropdownDC} from "../../../components/form/FormInputDropdownDC";
import {ISaveProductForm} from "../ISaveProductForm";

export const Step1IdentificationContainer: React.FC<ISaveProductForm> = (
    {control, updateId}) => {

    if (control != null) {
        return (

            <Grid container spacing={2}>
                <Grid item md={12}>
                    <FormInputText name="name" control={control} label="name" disabled={updateId != undefined}/>
                </Grid>
                <Grid item md={12}>
                    <FormInputRadioCore name="deploy" control={control} label="Déployer" disabled={updateId != undefined}/>
                </Grid>
                <Grid item md={12}>
                    <FormInputText name="serverAlias" control={control} label="serverAlias" disabled={updateId != undefined}/>
                </Grid>
                <Grid item md={12}>
                    <FormInputDropdownDF name="defaultLanguage" control={control} label="defaultLanguage"/>
                </Grid>
                <Grid item md={12}>
                    <FormInputDropdownDC name="defaultCurrency" control={control} label="defaultCurrency"/>
                </Grid>
            </Grid>
        )
    } else {
        return <></>;
    }


}