import * as React from 'react';
import {Label, Line, LineChart, ResponsiveContainer, XAxis, YAxis} from "recharts";
import {useTheme} from "@mui/material/styles";
import {IMetric} from "../types/index";

export default function InfosGraph(props:any) {
    const theme = useTheme()

    let label : string

    switch(props.type) {
        case 'cpu':
            label = "Charge CPU (%)"
        break
        case 'memory':
            label = "Mémoire (%)"
            break
        case 'pods':
            label = "Pods"
            break
        default:
            label = ''
    }

    return (
        <ResponsiveContainer>
            <LineChart
                data={props.data.filter(function(item:IMetric){
                    return item.type === props.type;
                })}
                margin={{
                    top: 16,
                    right: 16,
                    bottom: 24,
                    left: 24,
                }}
            >
                <XAxis
                    dataKey="time"
                    stroke={theme.palette.text.secondary}
                    style={theme.typography.caption}
                    angle={-40}
                    tickMargin={20}
                    label={{
                        position: "insideBottom",
                        angle: -60,
                        fill: "red",
                        offset: 25
                    }}
                />
                <YAxis
                    stroke={theme.palette.text.secondary}
                    style={theme.typography.body2}
                >
                    <Label
                        angle={270}
                        position="left"
                        style={{
                            textAnchor: 'middle',
                            fill: theme.palette.text.primary,
                            ...theme.typography.body1,
                        }}
                    >
                        {label}
                    </Label>
                </YAxis>
                <Line
                    isAnimationActive={false}
                    type="monotone"
                    dataKey="usage_percentage"
                    stroke={theme.palette.primary.main}
                    dot={false}
                />
            </LineChart>
        </ResponsiveContainer>
    );
}