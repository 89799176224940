import * as React from 'react';
import {AuthContextProps} from "react-oidc-context";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {ModuleDto, PackageMvn, PackageNpm, VersionLot} from "../../../types/index";
import catalogueService from "../../../services/CatalogueService";
import {AxiosResponse} from "axios";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";

export const AddVersionLot: React.FC<{
    module?: ModuleDto,
    refreshMode: any,
    auth: AuthContextProps
}> = (
    props: { module?: ModuleDto, refreshMode: any, auth: AuthContextProps }
) => {
    const [open, setOpen] = React.useState(false);
    const [messageError, setMessageError] = React.useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Container>
            <Button variant="contained" size={"large"} onClick={handleClickOpen}>
                Nouvelle version
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        setMessageError('');
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries((formData as any).entries());

                        const versionLot: VersionLot = {
                            numero: formJson.versionlotname,
                            description: formJson.description,
                            module : props.module,
                            packageMvnDtos: [],
                            packageNpmDtos: []
                        };

                        catalogueService.postVersionLot(props.auth, versionLot).then((value: AxiosResponse) => {
                            if (value.status < 300) {
                                props.refreshMode();
                                handleClose();
                            } else {
                                setMessageError('Erreur en retour du service');
                            }
                        }).catch(() => {
                            setMessageError('Erreur en retour du service');
                        });
                    },
                }}
            >
                <DialogTitle>Nouveau Module</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Permet d'ajouter un nouveau module.
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item md={12}>
                            <TextField autoFocus required margin="dense" id="versionlotname" name="versionlotname"
                                       label="Version du lot" type="text" fullWidth
                                       variant="outlined"
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField autoFocus required margin="dense" id="description"
                                       name="description"
                                       label="Description du lot" type="text" fullWidth
                                       variant="outlined"
                            />
                        </Grid>
                        <Grid item md={12}>
                            {messageError && messageError.length > 0 ?
                                <span>{messageError}</span> :
                                <></>
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleClose} color="error">Annuler</Button>
                    <Button variant="contained" type="submit">Créer</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}