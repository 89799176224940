import React from 'react';
import catalogueService from '../../services/CatalogueService';
import {AuthContextProps, useAuth} from "react-oidc-context";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {ModuleDto, VersionLot} from "../../types/index";
import Title from "../../components/Title";
import Grid from "@mui/material/Grid";
import {VersionLotListContainer} from "./container/VersionLotListContainer";
import {AddVersionLot} from "./components/AddVersionLot";
import {ModuleSearchComponent} from "./components/ModuleSearchComponent";
import {AddModuleComponent} from "./components/AddModuleComponent";
import {QueryClient, useQueryClient} from "@tanstack/react-query";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

//export declare
export default function StorePage(): JSX.Element {
    const auth: AuthContextProps = useAuth();
    const queryClient: QueryClient = useQueryClient();
    const {isFetching: boolean, ...queryInfo} = catalogueService.useGetModules(auth);

    const [moduleDto, setModuleDto] = React.useState<ModuleDto>();

    const [versionList, setVersionList] = React.useState(() => {
        const result: VersionLot[] = [];
        return result;
    });

    const refreshModeVersionList = (versionList: VersionLot[]) => {
        setVersionList(versionList);
    };
    const refreshModeListModule = () => {
        queryClient.invalidateQueries(['module']);
    };
    const refreshModeModule = (moduleDto: ModuleDto) => {
        setModuleDto(moduleDto);
    };

    const refreshMode = () => {
        if (moduleDto?.id) {
            catalogueService.getVersionlot(auth.user?.access_token, moduleDto.id).then((value: VersionLot[]) => {
                setVersionList(value);
            });
        }
    };

    return (
        <Container maxWidth="xl">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{my: 4}}>
                        <Card sx={{minWidth: 275}}>
                            <CardContent>
                                <Title> Catalogue des packages </Title>
                                {queryInfo.isSuccess && (
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item xs={7} md={7}>
                                            <ModuleSearchComponent moduleDtos={queryInfo.data}
                                                                   refreshModeVersionList={refreshModeVersionList}
                                                                   refreshModeModuleDto={refreshModeModule}
                                                                   auth={auth}/>
                                        </Grid>

                                        <Grid item xs={5} md={5}>
                                            <Grid container direction="row">
                                                <Grid item xs={6} md={6}>
                                                    <AddModuleComponent auth={auth}
                                                                        refreshMode={refreshModeListModule}/>
                                                </Grid>
                                                <Grid item xs={6} md={6}
                                                      hidden={moduleDto === undefined || moduleDto === null}>
                                                    <AddVersionLot module={moduleDto} auth={auth}
                                                                   refreshMode={refreshMode}/>
                                                </Grid>
                                            </Grid>

                                        </Grid>

                                        <Grid item xs={12} md={12}>
                                            <VersionLotListContainer versionList={versionList} auth={auth}
                                                                     refreshMode={refreshMode}/>
                                        </Grid>
                                    </Grid>
                                )}
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}