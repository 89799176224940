import * as React from 'react';
import {ModuleDto, VersionLot} from "../../../types/index";
import {AuthContextProps} from "react-oidc-context";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {Autocomplete} from "@mui/material";
import {AutocompleteValue} from "@mui/base/useAutocomplete/useAutocomplete";
import catalogueService from "../../../services/CatalogueService";

export const ModuleSearchComponent: React.FC<{
    moduleDtos: ModuleDto[],
    refreshModeVersionList: any,
    refreshModeModuleDto?: any,
    auth: AuthContextProps
}> = (
    props: { moduleDtos: ModuleDto[], refreshModeVersionList: any, refreshModeModuleDto?: any, auth: AuthContextProps }
) => {

    const onChangeAutoCompleted = (event: any, autocompleteValue: AutocompleteValue<any, any, any, any>) => {
        if (autocompleteValue?.id > 0) {
            catalogueService.getVersionlot(props.auth.user?.access_token, autocompleteValue.id).then((value: VersionLot[]) => {
                props.refreshModeVersionList(value);
            });

            if(props?.refreshModeModuleDto){
                props.refreshModeModuleDto(autocompleteValue);
            }
        } else {
            props.refreshModeVersionList([]);
            if(props?.refreshModeModuleDto){
                props.refreshModeModuleDto(undefined);
            }
        }
    };

    return (
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={props.moduleDtos}
            onChange={onChangeAutoCompleted}
            getOptionLabel={(option: ModuleDto) => option.name}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    {option.name} [{option.description}]
                </Box>
            )}
            fullWidth={true}
            renderInput={(params) => <TextField {...params} label="Packages"/>}
        />
    );
}