import React from "react";
import {Link as RouterLink, useNavigate, useParams} from "react-router-dom";
import productService, {ISavingProduct} from "../../services/ProductService";
import {QueryClient} from "@tanstack/react-query";
import {AuthContextProps, useAuth} from "react-oidc-context";
import * as yup from "yup";
import Loading from "../../components/Loading";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Assembly, IFormInputProduct, VersionLot} from "../../types/index";
import {ISaveProductForm} from "./ISaveProductForm";
import CardContent from "@mui/material/CardContent";
import Title from "../../components/Title";
import {Button, CardActions, Step, StepLabel, Stepper} from "@mui/material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {Step1IdentificationContainer} from "./container/Step1IdentificationContainer";
import {Step2BddContainer} from "./container/Step2BddContainer";
import {Step3ModuleContainer} from "./container/Step3ModuleContainer";
import {AxiosError} from "axios";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

interface IPorductServiceReturn {
    isLoading?: boolean;
    error?: any;
    data?: Assembly;
}

interface IDefaultValues {
    name: string,
    serverAlias: string,
    deploy: boolean,
    database?: {
        hostname?: string,
        port?: string,
        username?: string,
        password?: string,
        name?: string
    },
    defaultLanguage: string,
    defaultCurrency: string
}

export const SaveProductPage: React.FC = () => {
    const auth: AuthContextProps = useAuth();
    const queryClient: QueryClient = new QueryClient();
    const navigate: NavigateFunction = useNavigate();

    const {step} = useParams<"step">();
    const {updateId} = useParams<"updateId">();

    let iPorductServiceReturn: IPorductServiceReturn = productService.getProductById(auth, updateId);

    const [versionList, setVersionList] = React.useState(() => {
        const result: VersionLot[] = [];
        return result;
    });

    const steps = [
        'Identification',
        'Base de données',
        'Modules',
    ];

    const updateVersionLotChoose = (versionList: VersionLot[]) => {
        setVersionList(versionList);
    }

    let defaultValues: IDefaultValues = {
        name: "",
        serverAlias: "",
        deploy: false,
        database: {
            hostname: "",
            port: "",
            username: "",
            password: "",
            name: ""
        },
        defaultLanguage: "FR",
        defaultCurrency: "EUR"
    }

    const validationSchema = yup.object().shape({
        name: yup.string().required("name is a required field"),
        serverAlias: yup.string(),
        database: yup.object().shape({
            hostname: yup.string().required("hostname is a required field"),
            port: yup.string().required("port is a required field"),
            username: yup.string().required("username is a required field"),
            password: yup.string().required("password is a required field"),
            name: yup.string().required("name is a required field"),
        }),
        defaultLanguage: yup.string().required("defaultLanguage is a required field"),
        defaultCurrency: yup.string().required("defaultCurrency is a required field"),
    });

    const chooseStep = (index: number) => {

        if (index === 1) {
            return <Step1IdentificationContainer control={iSaveProductForm.control}/>;
        } else if (index === 2) {
            return <Step2BddContainer control={iSaveProductForm.control}/>;
        } else if (index === 3) {
            return <Step3ModuleContainer auth={auth} updateId={updateId} versionLots={versionList}
                                         updateVersionLotChoose={updateVersionLotChoose}/>;
        }

        return <p>Erreur</p>;
    }

    const iSaveProductForm: ISaveProductForm = useForm<any>({
        defaultValues: defaultValues,
        resolver: yupResolver(validationSchema),
    });

    React.useEffect(() => {
        if (iPorductServiceReturn.data) {
            defaultValues = {
                name: iPorductServiceReturn.data.name,
                serverAlias: iPorductServiceReturn.data.serverAlias,
                deploy: iPorductServiceReturn.data.deploy,
                database: {
                    hostname: iPorductServiceReturn.data.assemblyDatabase?.hostname,
                    port: iPorductServiceReturn.data.assemblyDatabase?.port,
                    username: iPorductServiceReturn.data.assemblyDatabase?.username,
                    password: "",
                    name: iPorductServiceReturn.data.assemblyDatabase?.name
                },
                defaultLanguage: "FR",
                defaultCurrency: "EUR"
            };

            setVersionList(iPorductServiceReturn.data.versionLots);

            iSaveProductForm.reset(defaultValues);
        }


    }, [iPorductServiceReturn.data]);

    const urlGenerate = (index: number): string => {

        if (updateId) {
            return `/product/${updateId}/step/${index}`;
        } else {
            return `/create/step/${index}`;
        }
    }

    const messageError = (errorCreating: any) => {
        if (errorCreating instanceof AxiosError) {
            return (
                <p>Create error : {errorCreating.response?.data}</p>
            );
        } else {
            return (
                <p>Create error : {errorCreating.message}</p>
            );
        }
    };

    const onSubmit = (iFormInputProduct: IFormInputProduct) => {
        iFormInputProduct.versionList = versionList;
        console.log(iFormInputProduct);

        if (updateId) {
            putProduct.SaveProduct(iFormInputProduct);
        } else {
            postProduct.SaveProduct(iFormInputProduct);
        }
    }

    const postProduct: ISavingProduct = productService.usePostProduct(auth, queryClient, navigate);

    const putProduct: ISavingProduct = productService.usePutProduct(auth, updateId, queryClient, navigate);

    if (updateId && iPorductServiceReturn.isLoading) {
        return (
            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{my: 4}}>
                            <Loading/>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        )
    } else {
        return (
            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{my: 4}}>
                            <Card sx={{minWidth: 275}}>
                                <CardContent>
                                    <Title>
                                        {!updateId && 'Nouveau produit'}
                                        {updateId && 'Modification produit'}
                                    </Title>

                                    <Stepper activeStep={Number(step) - 1} alternativeLabel>
                                        {steps.map((label: string, index: number) => (
                                            <Step key={label}>
                                                <StepLabel>{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>

                                    {chooseStep(Number(step))}

                                </CardContent>
                                <CardActions>
                                    <Grid container direction="row" justifyContent="space-between" alignItems="stretch">
                                        <Grid item xs={12} md={12}>
                                            {postProduct.isErrorSaving && messageError(postProduct.errorSaving)}
                                            {putProduct.isErrorSaving && messageError(putProduct.errorSaving)}
                                            {(postProduct.isSaving || putProduct.isSaving) && <p>Work..</p>}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Button component={RouterLink} to={urlGenerate(Number(step) - 1)}
                                                    disabled={Number(step) - 1 <= 0}>
                                                Retour
                                            </Button>
                                        </Grid>
                                        <Grid container item xs={12} md={6} justifyContent="flex-end">

                                            {Number(step) + 1 > 3 ? (
                                                <Button onClick={iSaveProductForm.handleSubmit(onSubmit)}
                                                        variant={"contained"}>
                                                    {!updateId && 'Créer'}
                                                    {updateId && 'Modifier'}
                                                </Button>
                                            ) : (
                                                <Button component={RouterLink} to={urlGenerate(Number(step) + 1)}>
                                                    Suivant
                                                </Button>
                                            )}

                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}