import React from 'react';
import {AppBar, IconButton, Toolbar, Typography, useTheme} from '@mui/material';
import {AccountCircle, ChevronLeft as ChevronLeftIcon, Menu as MenuIcon} from '@mui/icons-material';

import {useDrawerContext} from '../contexts/drawer-context';
import {AuthContextProps, useAuth} from "react-oidc-context";

export const Header = () => {
  const auth: AuthContextProps = useAuth();
  const { isOpened, toggleIsOpened } = useDrawerContext();
  const theme = useTheme();
  return (
    <AppBar
      sx={{ backgroundColor: 'primary.main', color: 'secondary.contrastText' }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          onClick={() => toggleIsOpened(!isOpened)}
          sx={{ padding: theme.spacing(1) }}
        >
          {isOpened ? <ChevronLeftIcon /> : <MenuIcon />}
        </IconButton>
        <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
        >
          One portal builder
        </Typography>
        {auth.isAuthenticated && (
            <div>
                {auth.user?.profile.preferred_username}
            </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
