import * as React from 'react';
import {useQueryClient} from '@tanstack/react-query'

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import {Button} from "@mui/material";

import Loading from "../../components/Loading";
import TitleCard from "../../components/TitleCard";
import ProductListDeletedComponent from "./Cards/ProductListDeletedComponent";
import Chip from "@mui/material/Chip";
import productService from "../../services/ProductService";
import {AuthContextProps, useAuth} from "react-oidc-context";

export default function ProductsListDelete() {
    const queryClient = useQueryClient();
    const auth: AuthContextProps = useAuth();
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    //const [intervalMs, setIntervalMs] = React.useState(2000)

    const {status, error, data, isFetching, isPreviousData} = productService.listDeleteProductAssembly(auth, page);

    React.useEffect(() => {
        if (!isPreviousData && data?.hasMore) {
            productService.listeDeleteProductAssemblyPage(auth, queryClient, page);
        }
    }, [data, isPreviousData, page, queryClient])

    // new
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 5));
        setPage(0);
    };

    return (
        <Box sx={{my: 4}}>
            {status === 'loading' ? (
                <Loading/>
            ) : status === 'error' ? (
                <div>Error: {'error.message'}</div>
            ) : (
                <Card sx={{minWidth: 275}}>
                    <CardContent>
                        <TitleCard>Liste des produits supprimés</TitleCard>
                        <ProductListDeletedComponent data={data.content}/>
                    </CardContent>
                    <Stack sx={{m: 1}} direction="row" justifyContent="flex-end" spacing={0}>
                        <Button
                            onClick={() => setPage((old) => Math.max(old - 1, 0))}
                            disabled={page === 0}
                        >
                            Previous Page
                        </Button>
                        <Chip label={`${page + 1}/${data?.totalPages}`} variant="outlined"/>
                        <Button
                            onClick={() => {
                                setPage((old) => (!data?.last ? old + 1 : old))
                            }}
                            disabled={isPreviousData || data?.last}
                        >
                            Next Page
                        </Button>
                    </Stack>
                </Card>
            )}
        </Box>
    );
}