import axios, {AxiosInstance, AxiosResponse} from "axios";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {apiUrl} from "../configuration";
import packageJson from '../../package.json';

export const api: AxiosInstance = axios.create({
    maxRedirects: 0,
    baseURL: `${apiUrl}`
})

export class ApplicationInfo {
    public backend: string | undefined;
    public frontend: string | undefined;
}

class InfoService {

    public getApplicationInfo(): UseQueryResult<ApplicationInfo, any> {
        return useQuery({
            queryKey: ['applicationInfo'],
            queryFn: async (): Promise<ApplicationInfo> => {
                const res : AxiosResponse<any, any> = await api.get<ApplicationInfo>(`/actuator/info`, {});
                const resultat: ApplicationInfo = new ApplicationInfo();
                if(res.status == 200){
                    resultat.backend = '['+res.data.build.name+ ':'+res.data.build.version+']';
                    resultat.frontend = '['+packageJson.name+ ':'+packageJson.version+']';
                }
                return resultat;
            },
            keepPreviousData: true,
        })
    }
}

const infoService: InfoService = new InfoService();
export default infoService;