import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {ListItem, ListItemIcon, ListItemText} from '@mui/material';

import {IMenuItem} from '../types';

type Props = IMenuItem & {
  selected?: boolean;
  onClick?: () => void;
};
export const MenuItem: React.FC<Props> = ({
route,
  literal,
  Icon,
  selected,
  onClick,
}) => {
  const link = (
    <ListItem
      button
      selected={selected}
      onClick={onClick}
    >
      <ListItemIcon
        sx={[
          { minWidth: 'auto' },
          (theme) => ({
            paddingRight: theme.spacing(2),
          }),
        ]}
      >
        <Icon />
      </ListItemIcon>
      <ListItemText primary={literal} />
    </ListItem>
  );

  return route
    ? <RouterLink to={route}>{link}</RouterLink>
    : link;
};
