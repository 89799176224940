import * as React from 'react';
import {createRoot} from 'react-dom/client';
import ManagementPage from "./ManagementPage";
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
const queryClient = new QueryClient();


root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
        <ManagementPage/>
        </QueryClientProvider>
    </React.StrictMode>
);