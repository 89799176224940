import React from "react";
import {FormInputText} from "../../../components/form/FormInputText";
import Grid from "@mui/material/Grid";
import {ISaveProductForm} from "../ISaveProductForm";

export const Step2BddContainer: React.FC<ISaveProductForm> = (
    {control}) => {

    return (

        <Grid container spacing={2}>
            <Grid item md={12}>
                <FormInputText name="database.hostname" control={control} label="hostname"/>
            </Grid>
            <Grid item md={12}>
                <FormInputText name="database.port" control={control} label="port"/>
            </Grid>
            <Grid item md={12}>
                <FormInputText name="database.username" control={control} label="username"/>
            </Grid>
            <Grid item md={12}>
                <FormInputText name="database.password" control={control} label="password" type="password"/>
            </Grid>
            <Grid item md={12}>
                <FormInputText name="database.name" control={control} label="name"/>
            </Grid>
        </Grid>
    )
}