import * as React from 'react';
import {useState} from 'react';
import MainRoutes from './routes'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import theme from "./theme";
import {Layout} from './components';
import {ConfirmProvider} from "material-ui-confirm";

import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/fr'

import {ThemeProvider} from "@mui/material/styles";
import {AuthContextProps, useAuth} from "react-oidc-context";
import {Link, useNavigate} from "react-router-dom";
import {NavigateFunction} from "react-router/dist/lib/hooks";

const queryClient = new QueryClient()

export default function App() {

    const [open, setOpen] = useState(true);
    const navigate: NavigateFunction = useNavigate();
    const auth: AuthContextProps = useAuth();

    switch (auth.activeNavigator) {
        case "signinSilent":
            return <div>Signing you in...</div>;
        case "signoutRedirect":
            return <div>Signing you out...</div>;
    }

    if (auth.isLoading) {
        return (
            <ThemeProvider theme={theme}>

                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                        <ConfirmProvider>
                            <Layout>
                                <div>Chargement...</div>
                            </Layout>
                        </ConfirmProvider>
                    </LocalizationProvider>
            </ThemeProvider>
        );
    } else if (auth.error) {
        auth.clearStaleState();
        auth.signinRedirect();
        navigate('/');
        return (
            <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                        <ConfirmProvider>
                            <Layout>
                                <div>Oops... {auth.error.message}</div>
                            </Layout>
                        </ConfirmProvider>
                    </LocalizationProvider>
            </ThemeProvider>
        );
    } else if (auth.isAuthenticated) {
        return (
            <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                        <ConfirmProvider>
                            <Layout>
                                <MainRoutes/>
                            </Layout>
                        </ConfirmProvider>
                    </LocalizationProvider>
                    {<ReactQueryDevtools initialIsOpen={false}/>}
            </ThemeProvider>
        );
    } else {
        auth.signinRedirect();
        return (
            <div></div>
        );
    }
}