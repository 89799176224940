import * as React from 'react';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {CircularProgress} from "@mui/material";

export default function Loading() {
    return (
        <Box sx={{ m: 4 }} >
            <Grid container spacing={1} minHeight={160}>
                <Grid display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress />
                </Grid>
            </Grid>
        </Box>
    );
}