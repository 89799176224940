import * as React from 'react';
import {PackageNpm, VersionLot} from "../../../types/index";
import {AuthContextProps} from "react-oidc-context";
import catalogueService from "../../../services/CatalogueService";
import {
    Autocomplete,
    Button,
    createFilterOptions,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {AutocompleteValue} from "@mui/base/useAutocomplete/useAutocomplete";

class PackageNpmDto {
    id: number | undefined;
    name: string | undefined;
    version: string | undefined;
}


export const PackageNpmDialogComponent: React.FC<{
    packageNpmDtos: PackageNpm[],
    versionLot: VersionLot,
    auth: AuthContextProps,
    updatePackageNpmDtos: any,
    hidden: boolean
}> = (
    props: {
        packageNpmDtos: PackageNpm[],
        versionLot: VersionLot,
        auth: AuthContextProps,
        updatePackageNpmDtos: any,
        hidden: boolean
    }
) => {
    const [open, setOpen] = React.useState(false);
    const [packageNpmDto, setPackageNpmDto] = React.useState(new PackageNpmDto());

    const {isFetching: boolean, ...queryInfo} = catalogueService.useGetPackageNpm(props.auth);

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        ignoreAccents: true,
        ignoreCase: true,
        stringify: (option: PackageNpm) => option.name + ' ' + option.version
    });

    const onChangeAutoCompleted = (event: any, autocompleteValue: AutocompleteValue<any, any, any, any>) => {
        if (autocompleteValue?.name && autocompleteValue?.version) {
            const packageNpm: PackageNpmDto = {
                id: undefined,
                name: autocompleteValue.name,
                version: autocompleteValue.version
            };
            setPackageNpmDto(packageNpm);
        }
    };

    if (props.hidden) {
        return (<></>);
    } else {


        return (

            <Grid container direction="row" justifyContent="space-between" alignItems="stretch" spacing={1}>
                <Grid item xs={12} md={12}>
                    <Button variant="contained" size={"large"} onClick={handleClickOpen}>
                        Ajouter Front
                    </Button>
                </Grid>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    maxWidth={'xl'}
                    fullWidth
                    PaperProps={{
                        component: 'form',
                        sx: {
                            height: 600
                        },
                        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                            event.preventDefault();
                            if (packageNpmDto?.name) {
                                catalogueService.addPackageNpmDto(props.auth, props.versionLot.id, packageNpmDto).then(value => {
                                    props.updatePackageNpmDtos(value.data.packageNpmDtos);
                                })
                            }

                            handleClose();
                        },
                    }}
                >
                    <DialogTitle>Nouveau Module Front</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Permet d'ajouter un nouveau module front.
                        </DialogContentText>
                        {queryInfo.isSuccess && (
                            <Grid container spacing={2}>
                                <Grid item md={12}>

                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        size={"medium"}
                                        onChange={onChangeAutoCompleted}
                                        options={queryInfo.data}
                                        filterOptions={filterOptions}
                                        getOptionLabel={(option: PackageNpm) => option.name + ' ' + option.version}
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props}>
                                                {option.name} [{option.version}]
                                            </Box>
                                        )}
                                        fullWidth={true}
                                        renderInput={(params) => <TextField {...params} label="NPM"/>}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleClose} color="error">Annuler</Button>
                        <Button variant="contained" type="submit">Créer</Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        )
    }
}