import * as React from 'react';
import {styled} from '@mui/material/styles';
import {Chip, Table, TableBody, TableHead, TableRow} from '@mui/material';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 16,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function ProductListDeletedComponent(props:any) {
    if(!props.data || !props.data.map) return (<></>)
    return (
            <Table sx={{ minWidth: 350 }} aria-label="product table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Nom</StyledTableCell>
                        <StyledTableCell align="left">Status</StyledTableCell>
                        <StyledTableCell align="left">id</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data.map((row: {
                        id: any,
                        deletePortalAssemblyStatus: string,
                        name: string; }) => (
                        <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <StyledTableCell component="th" scope="row">
                                {row.name}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                <Chip label={row.deletePortalAssemblyStatus} color="primary" size="small" />
                            </StyledTableCell>
                            <StyledTableCell align="left">{row.id}</StyledTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
    );
}