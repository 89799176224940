import {api} from "../api";
import {AxiosResponse} from "axios";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {AuthProviderProps} from "react-oidc-context";

export class KeycloakConfiguration {
    public authority: string | undefined;
    public clientId: string| undefined;
    public redirectUri: string| undefined;
    public onSigninCallback():void {
        window.history.replaceState(
            {},
            document.title,
        window.location.pathname
        )
    }
}

class ConfigurationService {

    public getKeycloakConfiguration(): UseQueryResult<any, any> {
        return useQuery({
            queryKey: ['configurationKeycloak'],
            queryFn: async (): Promise<any> => {
                const res : AxiosResponse<any, any> = await api.get<KeycloakConfiguration>(`/config-builder`, {});
                const resultat: any = {};
                if(res.status == 200){
                    resultat.authority = res.data.authority;
                    resultat.client_id = res.data.clientId;
                    resultat.redirect_uri = res.data.redirectUri;
                }
                return resultat;
            },
            keepPreviousData: true,
        })
    }

}

const configurationService: ConfigurationService = new ConfigurationService();
export default configurationService;