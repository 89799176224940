import * as React from 'react';
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import {useConfirm} from "material-ui-confirm";
import productService from "../../../services/ProductService";
import {AuthContextProps, useAuth} from "react-oidc-context";
import {NavigateFunction} from "react-router/dist/lib/hooks";


export default function DeleteComponent(props: any) {
    const auth: AuthContextProps = useAuth();
    const confirm = useConfirm();
    const navigate: NavigateFunction = useNavigate();

    const actionClickDelete = () => {
        confirm({
            title: "Êtes-vous sûr de vouloir supprimer le produit.",
            description: `Le status du projet est actuellement en ${props.status}`,
            cancellationText: "Annuler"
        })
            .then(() => {
                productService.deleteProductAssembly(auth, props, navigate);
            }).catch(() => {
            /* ... */
        });
    };

    return <Button onClick={actionClickDelete}>Delete</Button>;
}