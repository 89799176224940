import * as React from "react";
import PodComponent from "./Pod";
import Loading from "../../../components/Loading"
import {Grid} from "@mui/material";
import productService from "../../../services/ProductService";
import {AuthContextProps, useAuth} from "react-oidc-context";

export interface IItemPod {
    name: string;
    status: string;
    "restart-count": number;
    age: string,
    "node_name": string;
}

const renderPod = (pos:number, item: IItemPod) => (
    <Grid item md={6} sm={12} xs={12}>
        <PodComponent pos={pos} name={item.name} nodeName={item.node_name} status={item.status}/>
    </Grid>
);

export default function ActivityTab(props:any) {
    const auth: AuthContextProps = useAuth();
    const { isLoading, error, data, isFetching } = productService.productAssemblyPods(auth, props);

    if (isLoading) {
        return <Loading />
    }

    return (
        <>
            {
                data.map((item: IItemPod, index:number) => {
                    return renderPod(index, item)
                })
            }
        </>
    );
}