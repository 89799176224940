import React, {ReactNode} from 'react';
import {Box, styled, Toolbar} from "@mui/material";

import {Header} from "./Header";
import {Drawer} from "./Drawer";
import {Footer} from "./Footer";
import {Main} from "./Main";

import {DrawerContextProvider} from "../contexts/drawer-context";
import configurationService from "../services/ConfigurationService";
import infoService from "../services/InfoService";
import Grid from "@mui/material/Grid";

const OuterContainer = styled(Box)`
  display: flex;
  overflow: hidden;
  height: inherit;
  flex-direction: column;
  min-height: 100vh;
  background-color: rgb(245,245,245);
`;

const InnerContainer = styled(Box)`
  display: flex;
  flex: 1;
  overflow: hidden;
  height: inherit;
`;

interface ILayoutProps {
  children: NonNullable<ReactNode>;
}

export const Layout = ({ children }: ILayoutProps) =>{

  const {  data} = infoService.getApplicationInfo();

  return (
      <DrawerContextProvider>
        <OuterContainer>
          <Header />
          <Toolbar />
          <InnerContainer>
            <Drawer />
            <Main>{children}</Main>
          </InnerContainer>
          <Footer>
              Lucem - {data?.frontend} - {data?.backend}
          </Footer>
        </OuterContainer>
      </DrawerContextProvider>
  )
} ;
