import React from "react";
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup,} from "@mui/material";
import {Controller} from "react-hook-form";
import {FormInputProps} from "./FormInputProps";

const options = [
  {
    label: "Oui",
    value: true
  },
  {
    label: "Non",
    value: false
  },
];

export const FormInputRadioCore: React.FC<FormInputProps> = ({
  name,
  control,
  label,
  setValue
}) => {
  const generateRadioOptions = () => {
    return options.map((singleOption, index) => (
      <FormControlLabel
        key={index}
        value={singleOption.value}
        label={singleOption.label}
        control={<Radio />}
      />
    ));
  };

  return (
    <FormControl component="fieldset" key="jjjjj-key">
      <FormLabel component="legend">{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, value },
          fieldState: { error },
          formState,
        }) => (
          <RadioGroup value={value} onChange={onChange} row>
            {generateRadioOptions()}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};
