import * as React from 'react';
import {styled} from '@mui/material/styles';
import {Button, Chip, CircularProgress, Table, TableBody, TableHead, TableRow} from '@mui/material';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import {Link as RouterLink} from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 16,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function ProductListComponent(props:any) {
    if(!props.data || !props.data.map) return (<></>)
    return (
            <Table sx={{ minWidth: 350 }} aria-label="product table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Nom</StyledTableCell>
                        <StyledTableCell align="left">Étape</StyledTableCell>
                        <StyledTableCell align="center">Status</StyledTableCell>
                        <StyledTableCell align="center"></StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data.map((row: {
                        id: any,
                        name: string,
                        step: string;
                        status: string; }) => (
                        <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <StyledTableCell component="th" scope="row">
                                {row.name}
                            </StyledTableCell>
                            <StyledTableCell align="left">{row.step}</StyledTableCell>
                            <StyledTableCell align="center">
                                {row.status == 'RUNNING' ? (
                                    <CircularProgress size="2rem" />
                                ) : (
                                    <Chip label={row.status} color="primary" size="small" />
                                )}
                            </StyledTableCell>
                            <StyledTableCell align="center">

                                <Button component={RouterLink} to={`/product/${row.id}`}>
                                    Voir
                                </Button>

                                <Button component={RouterLink} to={`/product/${row.id}/step/1`}>
                                    Modifier
                                </Button>
                            </StyledTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
    );
}