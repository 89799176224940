import * as React from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Table, TableBody, TableHead, TableRow} from "@mui/material";
import {VersionLot} from "../../../types/index";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {PackageMvnListContainer} from "./PackageMvnListContainer";
import {PackageNpmContainer} from "./PackageNpmContainer";
import Grid from "@mui/material/Grid";
import {AuthContextProps} from "react-oidc-context";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import IconButton from "@mui/material/IconButton";
import catalogueService from "../../../services/CatalogueService";

export const VersionLotListContainer: React.FC<{versionList : VersionLot[],refreshMode: any, auth: AuthContextProps }> = (
    props: {versionList : VersionLot[],refreshMode: any, auth: AuthContextProps }) => {

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const onDeleteVersionLot = (idVersionLot: number | undefined) => {
        if (idVersionLot) {
            catalogueService.deleteVersionLot(props.auth, idVersionLot).then(value => {
                props.refreshMode();
            })
        }
    }

    if (props &&  props?.versionList.length > 0) {
        props.versionList.sort((a, b) => a.numero > b.numero ? -1 : 1);
        return (<>
            { props.versionList.map((value: VersionLot) => {
                return (
                    <Accordion expanded={expanded === `panel${value.numero}`} onChange={handleChange(`panel${value.numero}`)} key={`panel${value.numero}bh`}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${value.numero}bh-content`} id={`panel${value.numero}bh-header`} >
                            <Grid container direction="row" justifyContent="space-between" alignItems="stretch" spacing={1}>
                                <Grid item xs={4} md={4}>
                                    {value.numero}
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    {value.description}
                                </Grid>
                                <Grid item xs={2} md={2}>
                                    <IconButton title={value.useInProduct ? 'Utilisé dans un produit':'Supprimer'} aria-label="delete" color="warning" disabled={value.useInProduct} onClick={event => {
                                        onDeleteVersionLot(value.id);
                                    }}>
                                        <DeleteOutlineIcon/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails sx={{ backgroundColor: "gray" }}>
                                <Grid container direction="row" justifyContent="space-between" alignItems="stretch" spacing={1}>
                                    <Grid item xs={12} md={6}>
                                        <PackageMvnListContainer versionLot={value} auth={props.auth} disabled={value.useInProduct} updateMode={true} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <PackageNpmContainer versionLot={value} auth={props.auth} disabled={value.useInProduct} updateMode={true}/>
                                    </Grid>
                                </Grid>
                        </AccordionDetails>
                    </Accordion>
                )
            })}</>
        );
    } else {
        return (<>Aucune données</>)
    }
}