import * as React from 'react';
import {useState} from 'react';
import {useQuery, useQueryClient} from "@tanstack/react-query";
import Loading from "../../../components/Loading";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Title from "../../../components/Title";
import dayjs, {Dayjs} from 'dayjs';
import MetricsGraph from "../../../components/MetricsGraph"
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {IMetric} from "../../../types/index";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import metricsService from "../../../services/MetricsService";
import {AuthContextProps, useAuth} from "react-oidc-context";

export default function MetricComp(props:any) {
    const auth: AuthContextProps = useAuth();
    const now = dayjs()

    const queryClient = useQueryClient()
    const [valueSince, setValueSince] = React.useState<Dayjs | null>(now.add(-1, 'day'));
    const [valueTo, setValueTo] = React.useState<Dayjs | null>(now);

    const [listePod, setListePod] = useState<string[]>([]);
    async function fetchMetrics(since: dayjs.Dayjs | null, to: dayjs.Dayjs | null) {
        const params = { since: since?.toDate(), to: to?.toDate() }
        const { data } = await metricsService.metrics(auth, params);
        data.map((d: IMetric) => {
            d.time = dayjs(d.date).format('DD/MM/YY')
        });

        let temp:string[] = [];
        data.map((d: IMetric) => {
            if ( temp.indexOf(d.name)==-1 ) {
                temp.push(d.name)
            }
        });
        setListePod([]);
        temp.map((n: string) => {
            setListePod(oldArray => [...oldArray, n]);
        });

        return data
    }

    const { isLoading, error, data, isFetching, isPreviousData } = useQuery({
        queryKey: ['metrics', valueSince, valueTo],
        queryFn: () => fetchMetrics(valueSince, valueTo),
        keepPreviousData: true,
    })

    React.useEffect(() => {
        if (!isPreviousData && data?.hasMore) {
            queryClient.prefetchQuery({
                queryKey: ['metrics', valueSince, valueTo],
                queryFn: () => fetchMetrics(valueSince, valueTo),
            })
        }
    }, [data, isPreviousData, valueSince, valueTo, queryClient])

    if (isLoading) {
        return <Loading />
    }

    return (
        <>
            <Grid item md={6} sm={12} xs={12} key={'metrics'}>
                <Card sx={{minHeight: 180}}>
                    <CardContent>
                        <Stack direction="row" spacing={2} justifyContent="space-between">
                            <Stack>
                                <Title>Nodes</Title>
                                <Typography component="p" variant="h1">
                                    {listePod.length}
                                </Typography>
                            </Stack>
                            <Stack spacing={2}>
                                <DatePicker
                                    label="valueSince"
                                    value={valueSince}
                                    maxDate={now}
                                    onChange={
                                        (newValue) => {
                                            if ( dayjs(valueSince).isAfter(valueTo) ) {
                                                setValueTo(newValue)
                                            }
                                            setValueSince(newValue)
                                        }
                                    }
                                />
                                <DatePicker
                                    label="valueTo"
                                    value={valueTo}
                                    minDate={valueSince}
                                    maxDate={now}
                                    onChange={(newValue) => setValueTo(newValue)}
                                />
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>

            {
                listePod.map((nodeName: string) => {
                    return <Grid item md={6} sm={12} xs={12} key={nodeName}>
                        <Paper
                        sx={{
                            p: 2,
                            mb: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 680,
                        }}
                    >
                        <Stack direction="row" justifyContent="space-between" spacing={2}>
                            <Title>{nodeName}</Title>
                        </Stack>

                        <MetricsGraph
                            type={'cpu'}
                            data={ data.filter(function(item:IMetric){ return item.name === nodeName;}) }
                        />
                        <MetricsGraph
                            type={'memory'}
                            data={ data.filter(function(item:IMetric){ return item.name === nodeName;}) }
                        />
                        <MetricsGraph
                            type={'pods'}
                            data={ data.filter(function(item:IMetric){ return item.name === nodeName;}) }
                        />
                    </Paper>
                    </Grid>
                })
            }
        </>
    );
}
