import * as React from 'react';
import {ModuleDto, PackageMvn, VersionLot} from "../../../types/index";
import {AuthContextProps} from "react-oidc-context";
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    InputLabel,
    MenuItem,
    Select, SelectChangeEvent
} from "@mui/material";
import catalogueService from "../../../services/CatalogueService";
import {AxiosResponse} from "axios";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";

export const AddModuleComponent: React.FC<{
    refreshMode: any,
    auth: AuthContextProps
}> = (
    props: { refreshMode: any, auth: AuthContextProps }
) => {
    const [open, setOpen] = React.useState(false);

    const [typeModule, setTypeModule] = React.useState('CORE');
    const [messageError, setMessageError] = React.useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setTypeModule(event.target.value as string);
    };


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Container>
            <Button variant="contained" size={"large"} onClick={handleClickOpen}>
                Nouveau Package
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        setMessageError('');
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries((formData as any).entries());
                        const name = formJson.name;
                        console.log(name);
                        const description = formJson.description;
                        console.log(description);

                        const module: ModuleDto = {
                            name: formJson.name,
                            description: formJson.description,
                            type: typeModule
                        };

                        catalogueService.postModule(props.auth, module).then((value: AxiosResponse) => {
                            if (value.status < 300) {
                                props.refreshMode();
                                handleClose();
                            } else {
                                setMessageError('Erreur en retour du service');
                            }
                        }).catch(() => {
                            setMessageError('Erreur en retour du service');
                        });
                    },
                }}
            >
                <DialogTitle>Nouveau Module</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Permet d'ajouter un nouveau module.
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item md={12}>
                            <TextField autoFocus required margin="dense" id="name" name="name"
                                       label="Nom du package" type="text" fullWidth
                                       variant="outlined"
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField autoFocus required margin="dense" id="description"
                                       name="description"
                                       label="Description du package" type="text" fullWidth
                                       variant="outlined"
                            />
                        </Grid>
                        <Grid item md={12}>
                            <InputLabel id="demo-simple-select-label">Type de module</InputLabel>
                            <Select labelId="demo-simple-select-label" id="demo-simple-select"
                                    value={typeModule}
                                    label="Type"
                                    onChange={handleChange}
                            >
                                <MenuItem value={'CORE'}>CORE</MenuItem>
                                <MenuItem value={'STANDARD'}>STANDARD</MenuItem>
                                <MenuItem value={'CLIENT'}>CLIENT</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item md={12}>
                            {messageError && messageError.length > 0 ?
                                <span>{messageError}</span> :
                                <></>
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleClose} color="error">Annuler</Button>
                    <Button variant="contained" type="submit">Créer</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}