import * as React from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import ProductsList from "./ProductsList";

export default function ProductsdPage() {

    return (
        <Container maxWidth="xl">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ProductsList />
                </Grid>
            </Grid>
        </Container>
    );
}