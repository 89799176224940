import React from 'react';
import {useLocation} from 'react-router-dom';
import {Box, Grid, List} from '@mui/material';

import {MenuItem} from './MenuItem';
import {IMenuItem} from '../types';

export const MenuItemsList = ({ items = [] }: { items?: IMenuItem[] }) => {
  const { pathname } = useLocation();

  if (!items.length) return null;

  return (
    <Grid>
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <List
                key={'menu-list'}
                sx={{ p: 0 }}>
                {items.map(({ literal, route, Icon }) => (
                    <MenuItem
                        Icon={Icon}
                        literal={literal}
                        route={route}
                        key={route}
                        selected={pathname === route}
                    />
                ))}
            </List>
        </Box>

    </Grid>
  );
};
